/*
 * @Author: LiZhiWei
 * @Date: 2024-05-09 11:44:50
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-06-20 09:15:13
 * @Description:
 */
import {
  MembershipApplication,
  type IMembershipDetail,
  type IMembershipInvoice,
} from '@/models/application'
import { http } from '~/utils/fetch/index'
import type { BasicResponse } from '~/models/common'
/**
 * 上传入会申请所需文件
 * @param data MembershipApplication
 * @returns
 */
export function postMembershipApplication(data: MembershipApplication) {
  return http.request<BasicResponse<number>>({
    url: `/membershipApplication/open`,
    method: 'post',
    data,
  })
}

export function getMembershipInfo() {
  return http.request<BasicResponse<IMembershipDetail>>({
    url: `/membership/userDetail`,
    method: 'get',
  })
}

export function getMembershipInvoice() {
  return http.request<BasicResponse<IMembershipInvoice[]>>({
    url: `/membershipPayment/userInvoice`,
    method: 'get',
  })
}
